import buttonStyles from '@styles/scss/buttons/_buttons.module.scss';
import styles from '@styles/scss/common/_error404.module.scss';

export default function Index(): JSX.Element {
    return (
        <div className={styles.errorContainer}>
            <div className={styles.errorOuter}>
                <div className={styles.errorInner}>
                    <div className={styles.errorTitle}>
                        <h1>404 : page not found</h1>
                    </div>
                    <div className={styles.errorText}>
                        <p>Sorry, we couldn’t find this page.</p>
                        <p>
                            The URL may be incorrect or the page you are looking for may have moved.
                        </p>
                    </div>
                    <a href="/" className={`${buttonStyles.primaryButton} ${styles.primaryButton}`}>
                        <span>
                            <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.5 1L0.5 6L5.5 11"
                                    stroke="white"
                                    strokeMiterlimit="10"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            BACK TO HOMEPAGE
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
}
