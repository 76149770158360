import Base from '@components/layouts/base';
import Footer from '@components/partials/common/footer';
import Header from '@components/partials/common/header';
import { getStaticData } from '@data/staticApi';
import styles from '@styles/scss/list/_listPage.module.scss';
import Error from 'components/partials/common/error';
import { FooterText } from 'lib/types/item/footer';
import { FooterLinks } from 'lib/types/item/footerLinks';
import { NavbarItem } from 'lib/types/item/navBarItem';
import { SidebarItem } from 'lib/types/item/sideBarItem';
import { GetStaticProps } from 'next';
import Head from 'next/head';

export default function Index({
    sideBarItem,
    navBarItem,
    footer,
    footerLinks
}: {
    sideBarItem: SidebarItem[];
    navBarItem: NavbarItem[];
    footer: FooterText;
    footerLinks: FooterLinks;
}): JSX.Element {
    return (
        <>
            <Base currentLang="en" performer="">
                <Head>
                    <title>Page not found</title>
                </Head>
                <Header
                    sideBarItems={sideBarItem}
                    navBarItems={navBarItem}
                    currentLang="en"
                    gender="girls"
                />
                <div className={styles.errorPage}>
                    <div className={styles.listContainer}>
                        <Error />
                    </div>
                </div>
                <Footer
                    footerText={footer}
                    footerLinks={footerLinks}
                    currentLang="en"
                    gender="girls"
                />
            </Base>
        </>
    );
}

export const getStaticProps: GetStaticProps = async (context: any) => {
    const language = context.locale;
    const staticApi = await getStaticData(language);

    if (!staticApi) {
        return {
            notFound: true
        };
    }

    return {
        props: {
            sideBarItem: staticApi.sidebarItems,
            navBarItem: staticApi.navbarItems,
            footer: staticApi.footer,
            footerLinks: staticApi.footerLinks
        }
    };
};
